import { default as Config } from '@/data/site'

const EnableGoogleAdSense = () => {
  const head = document.getElementsByTagName('head')[0]
  const scriptElement = document.createElement(`script`)
  scriptElement.type = `text/javascript`
  scriptElement.async
  scriptElement.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-${Config.google.adsense}`
  scriptElement.crossOrigin = "anonymous"
  head.appendChild(scriptElement)

  return
}

export default EnableGoogleAdSense