const url = 'https://iver.mx'
const siteURL =
  process.env.NODE_ENV == 'production' ? url : 'http://localhost:3000'

const config = {
  site: {
    name: "Iver's Blog",
    url: siteURL,
    title: '',
    logo: '/images/logo.png',
    imagePreview: '/images/preview.png',
    description: 'Un blog donde registrar temas nerdos',
    creator: '@iver',
    author: 'Iván Jaimes'
  },
  projects: [
    {
      name: 'A La Mexicana',
      url: 'https://alamexicana.dev'
    }
  ],
  facebook: 'ivan.iver',
  github: 'iver',
  social: {
    twitter: 'https://twitter.com/@iver14',
    github: 'https://github.com/iver',
    linkedin: 'https://mx.linkedin.com/in/ivaniver'
  },
  google: {
    adsense: '3432671328017658',
    trackGA: '',
    trackGTM: '',
    trackAW: '',
    conversionID: ''
  }
}

export default config
