import(/* webpackMode: "eager" */ "/vercel/path0/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/common/ParticleButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/cookie/CookieConsent.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"components/common/Typography.tsx\",\"import\":\"Open_Sans\",\"arguments\":[{\"weight\":[\"300\",\"400\",\"600\",\"700\"],\"style\":[\"normal\",\"italic\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-open-sans\"}],\"variableName\":\"OpenFont\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"components/common/Typography.tsx\",\"import\":\"Caveat\",\"arguments\":[{\"weight\":[\"400\",\"700\"],\"style\":[\"normal\"],\"subsets\":[\"latin\"],\"variable\":\"--font-logo\"}],\"variableName\":\"LogoFont\"}");
